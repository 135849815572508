import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  openSurveysData: [],
  openSurveysDataLoading: false,
  openSurveysDataError: null,

  selectedStix: null,

  questionsData: null,
  questionsDataLoading: false,
  questionsDataError: null,

  addAnswerResponse: null,
  addAnswerLoading: false,
  addAnswerError: null,

  generalInformationResponse: null,
  generalInformationLoading: false,
  generalInformationError: null,

  recentCompletedSurveyData: null,
  selectedAnswerValue: null,

  selectedMojoData: null,

  createMojoLoading: false,
  createMojoError: null,

  users: [],
  count: null,
  totalPages: null,
  usersLoading: false,
  userPage: 1,
  usersError: null,

  addOrEditUserLoading: false,
  addOrEditUserError: null,

  deleteUserLoading: false,
  deleteUserError: null,

  selectedUserForUpdate: null,

  userAccountSettingsLoading: false,
  userAccountSettingsError: null,

  addNameData: null,
  addNameLoading: false,
  addNameError: null,

  userRoles: null,
  userRolesLoading: false,
  userRolesError: null,
};

const getOpenSurveys = state => {
  state.openSurveysDataLoading = true;
  state.openSurveysDataError = null;
};

const getOpenSurveysSuccess = (state, action) => {
  state.openSurveysData = action.payload.filter(item => !item._Finalized);
  state.openSurveysDataLoading = false;
  state.openSurveysDataError = null;
};

const getOpenSurveysFailure = (state, action) => {
  state.openSurveysDataLoading = false;
  state.openSurveysDataError = action.payload;
};

const setSelectedStix = (state, action) => {
  state.selectedStix = action.payload;
};

const getQuestionsData = state => {
  state.questionsDataLoading = true;
  state.questionsDataError = null;
};

const getQuestionsDataSuccess = (state, action) => {
  state.questionsData = action.payload;
  state.questionsDataLoading = false;
  state.questionsDataError = null;
};

const getQuestionsDataFailure = (state, action) => {
  state.questionsDataLoading = false;
  state.questionsDataError = action.payload;
};

const addAnswer = (state, action) => {
  state.addAnswerLoading = true;
  state.addAnswerError = null;
  state.selectedMojoData = null;
};

const addAnswerSuccess = (state, action) => {
  state.addAnswerResponse = action.payload;
  state.addAnswerLoading = false;
  state.addAnswerError = null;
};

const addAnswerFailure = (state, action) => {
  state.addAnswerError = action.payload;
  state.addAnswerLoading = false;
};

const setRecentCompletedSurveyData = (state, action) => {
  state.recentCompletedSurveyData = action.payload;
};

const setSelectedAnswerValue = (state, action) => {
  state.selectedAnswerValue = action.payload;
};

const setSelectedMojoData = (state, action) => {
  state.selectedMojoData = action.payload;
};

const createMojo = (state, action) => {
  state.createMojoLoading = true;
  state.createMojoError = null;
};

const createMojoSuccess = (state, action) => {
  state.createMojoLoading = false;
  state.createMojoError = null;
};

const createMojoFailure = (state, action) => {
  state.createMojoError = action.payload;
  state.createMojoLoading = false;
};

const getUsers = (state, action) => {
  const {page} = action.payload;

  if (page === 1) {
    state.users = [];
  }
  state.userPage = page;
  state.usersLoading = true;
  state.usersError = null;
};

const getUsersSuccess = (state, action) => {
  const {
    respData: {count, listofUsersData, totalPages},
    page,
  } = action.payload;

  state.isUsers = listofUsersData;
  state.users = page === 1 ? listofUsersData : [...state.users, ...listofUsersData];
  state.count = count;
  state.totalPages = totalPages;
  state.usersLoading = false;
};

const getUsersFailure = (state, action) => {
  state.usersError = action.payload;
  state.usersLoading = false;
};

const addOrEditUser = state => {
  state.addOrEditUserLoading = true;
  state.addOrEditUserError = null;
};

const addOrEditUserSuccess = (state, action) => {
  state.addOrEditUserLoading = false;
  state.response = action.payload;
  state.addOrEditUserError = null;
};

const addOrEditUserFailure = (state, action) => {
  state.addOrEditUserLoading = false;
  state.addOrEditUserError = action.payload;
};

const setSelectedUserForUpdate = (state, action) => {
  state.selectedUserForUpdate = action.payload;
};

const deleteUser = (state, action) => {
  state.deleteUserLoading = true;
  state.deleteUserError = null;
};

const deleteUserSuccess = (state, action) => {
  state.deleteUserLoading = false;
  state.deleteUserError = null;
};

const deleteUserFailure = (state, action) => {
  state.deleteUserLoading = false;
  state.deleteUserError = action.payload;
};

const reactivateUser = (state, action) => {
  state.deleteUserLoading = true;
  state.deleteUserError = null;
};

const reactivateUserSuccess = (state, action) => {
  state.deleteUserLoading = false;
  state.deleteUserError = null;
};

const reactivateUserFailure = (state, action) => {
  state.deleteUserLoading = false;
  state.deleteUserError = action.payload;
};

const userAccountSettings = (state, action) => {
  state.userAccountSettingsLoading = true;
  state.userAccountSettingsError = null;
};

const userAccountSettingsSuccess = (state, action) => {
  state.userAccountSettingsLoading = false;
  state.userAccountSettingsError = null;
};

const userAccountSettingsFailure = (state, action) => {
  state.userAccountSettingsLoading = false;
  state.userAccountSettingsError = action.payload;
};

const addName = (state, action) => {
  state.addNameLoading = true;
  state.addNameError = null;
};

const addNameSuccess = (state, action) => {
  state.addNameLoading = false;
  state.addNameData = action.payload;
  state.addNameError = null;
};

const addNameFailed = (state, action) => {
  state.addNameLoading = false;
  state.addNameError = action.payload;
};

const userRoles = (state, action) => {
  state.userRolesLoading = true;
  state.userRolesError = null;
};

const userRolesSuccess = (state, action) => {
  state.userRolesLoading = false;
  state.userRoles = action.payload;
  state.userRolesError = null;
};

const userRolesFailure = (state, action) => {
  state.userRolesLoading = false;
  state.userRolesError = action.payload;
};

const authenticationSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {
    getOpenSurveys(state, action) {
      return getOpenSurveys(state, action);
    },
    getOpenSurveysSuccess(state, action) {
      return getOpenSurveysSuccess(state, action);
    },
    getOpenSurveysFailure(state, action) {
      return getOpenSurveysFailure(state, action);
    },
    setSelectedStix(state, action) {
      return setSelectedStix(state, action);
    },
    getQuestionsData(state, action) {
      return getQuestionsData(state, action);
    },
    getQuestionsDataSuccess(state, action) {
      return getQuestionsDataSuccess(state, action);
    },
    getQuestionsDataFailure(state, action) {
      return getQuestionsDataFailure(state, action);
    },
    addAnswer(state, action) {
      return addAnswer(state, action);
    },
    addAnswerSuccess(state, action) {
      return addAnswerSuccess(state, action);
    },
    addAnswerFailure(state, action) {
      return addAnswerFailure(state, action);
    },
    setRecentCompletedSurveyData(state, action) {
      return setRecentCompletedSurveyData(state, action);
    },
    setSelectedAnswerValue(state, action) {
      return setSelectedAnswerValue(state, action);
    },
    setSelectedMojoData(state, action) {
      return setSelectedMojoData(state, action);
    },
    createMojo(state, action) {
      return createMojo(state, action);
    },
    createMojoSuccess(state, action) {
      return createMojoSuccess(state, action);
    },
    createMojoFailure(state, action) {
      return createMojoFailure(state, action);
    },
    getUsers(state, action) {
      return getUsers(state, action);
    },
    getUsersSuccess(state, action) {
      return getUsersSuccess(state, action);
    },
    getUsersFailure(state, action) {
      return getUsersFailure(state, action);
    },
    addOrEditUser(state, action) {
      return addOrEditUser(state, action);
    },
    addOrEditUserSuccess(state, action) {
      return addOrEditUserSuccess(state, action);
    },
    addOrEditUserFailure(state, action) {
      return addOrEditUserFailure(state, action);
    },
    deleteUser(state, action) {
      return deleteUser(state, action);
    },
    deleteUserSuccess(state, action) {
      return deleteUserSuccess(state, action);
    },
    deleteUserFailure(state, action) {
      return deleteUserFailure(state, action);
    },
    reactivateUser(state, action) {
      return reactivateUser(state, action);
    },
    reactivateUserSuccess(state, action) {
      return reactivateUserSuccess(state, action);
    },
    reactivateUserFailure(state, action) {
      return reactivateUserFailure(state, action);
    },
    setSelectedUserForUpdate(state, action) {
      return setSelectedUserForUpdate(state, action);
    },
    userAccountSettings(state, action) {
      return userAccountSettings(state, action);
    },
    userAccountSettingsSuccess(state, action) {
      return userAccountSettingsSuccess(state, action);
    },
    userAccountSettingsFailure(state, action) {
      return userAccountSettingsFailure(state, action);
    },
    addName(state, action) {
      return addName(state, action);
    },
    addNameSuccess(state, action) {
      return addNameSuccess(state, action);
    },
    addNameFailed(state, action) {
      return addNameFailed(state, action);
    },
    userRoles(state, action) {
      return userRoles(state, action);
    },
    userRolesSuccess(state, action) {
      return userRolesSuccess(state, action);
    },
    userRolesFailure(state, action) {
      return userRolesFailure(state, action);
    },
  },
});

export const {name, actions, reducer} = authenticationSlice;
