import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Typography, Popper, Dialog, Box} from '@mui/material';
import {styled} from '@mui/system';
import {NimbleButton} from 'nimble-design-system';
import {useSelector} from 'react-redux';
import {colors} from 'theme';
import {DetailReport} from 'app/modules/report/containers';
import {BottomNavigation} from 'app/shared/components';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';
import Pagelayout from 'app/Layout/PageLayout';
import InfoPopper from 'app/modules/report/containers/infoPopper/infoPopper.component';
import {useApiResponseHandler} from 'app/utils/CustomHooks';
import base64ToExcelFile from 'app/utils/DownloadExcelFile';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {actions as ReportActions} from 'app/store/slices/report.slice';
import {changeFormatOfDate} from 'app/utils/validPassword';

import backImage from 'assets/images/tab/backButton.svg';
import message_question from 'assets/images/message_question.svg';

const OverallProgressPage = () => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isExportClicked, setIsExportClicked] = useState(false);

  const SurveyReport = useSelector(state => state.report.SurveyReport);
  const ExcelFileLoading = useSelector(state => state.report.surveyExcelFileLoading);
  const ExcelFileError = useSelector(state => state.report.surveyExcelFileError);
  const ExcelData = useSelector(state => state.report.surveyExcelFile);

  const handleClick = () => {
    setAnchorEl(true);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const HandleExport = e => {
    const sendParams = {
      Type: 'STIX',
      id: SurveyReport.idSurvey,
    };
    dispatch(ReportActions.getSurveyExcelFile(sendParams));
    setIsExportClicked(true);
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (ExcelData && isExportClicked) {
      base64ToExcelFile(
        ExcelData,
        `${SurveyReport.surveyName} ${changeFormatOfDate(new Date().toISOString().split('T')[0])}.xlsx`,
      );
    }
  }, [ExcelData, setIsExportClicked]);

  const updateDownloadExcelFileSuccess = () => {
    setIsExportClicked(false);
  };
  const updateDownloadExcelFileFailure = () => {};

  useApiResponseHandler(
    ExcelFileLoading,
    ExcelFileError,
    updateDownloadExcelFileSuccess,
    updateDownloadExcelFileFailure,
  );

  const DownloadPdf = () => {
    setIsDropdownOpen(!isDropdownOpen);
    const capture = document.querySelector('.actual-report');
    const {clientWidth, clientHeight, scrollWidth, scrollHeight} = capture;

    const canvasWidth = Math.max(clientWidth, scrollWidth) + 20;
    const canvasHeight = Math.max(clientHeight, scrollHeight) + 20;

    html2canvas(capture, {width: canvasWidth, height: canvasHeight}).then(canvas => {
      const imgData = canvas.toDataURL('.img/png');
      const doc = new jsPDF('p', 'mm', 'a5');

      const contentAspectRatio = canvasHeight / canvasWidth;

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = pdfWidth * contentAspectRatio;

      const paddingX = 10;
      const paddingY = 10;

      doc.addImage(imgData, 'PNG', paddingX, paddingY, pdfWidth - 2 * paddingX, pdfHeight - 2 * paddingY);

      doc.save(`${SurveyReport.surveyName} ${changeFormatOfDate(new Date().toISOString().split('T')[0])}.pdf`);
    });
  };

  return (
    <>
      <Pagelayout style={{background: colors.light6}}>
        <div>
          <RootDiv>
            <ContentTypo variant="h2" color={colors.primary}>
              REPORT
            </ContentTypo>

            {!isMobile ? (
              <HeaderExport>
                <DetailsWrapper>
                  <DropdownToggle onClick={toggleDropdown} isOpen={isDropdownOpen}>
                    Export
                  </DropdownToggle>
                  {isDropdownOpen && (
                    <DropdownList>
                      <DropdownItem onClick={DownloadPdf}>To PDF</DropdownItem>
                      <DropdownItem onClick={HandleExport}>To Excel</DropdownItem>
                    </DropdownList>
                  )}
                </DetailsWrapper>

                <ImageDiv onClick={handleClick}>
                  <img style={{cursor: 'pointer'}} src={message_question} alt="mojo" />
                </ImageDiv>
              </HeaderExport>
            ) : (
              <ImageDiv onClick={handleClick}>
                <img style={{cursor: 'pointer'}} src={message_question} alt="mojo" />
              </ImageDiv>
            )}
          </RootDiv>
          <ContentTypo variant="h3" color={colors.dark}>
            {SurveyReport?.surveyName}
          </ContentTypo>

          <div style={{marginTop: '40px', maxWidth: '350px'}} className="actual-report">
            <DetailReport />
          </div>

          {!isMobile && (
            <BackButton>
              <NimbleButton
                color={colors.light4}
                fontWeight="700"
                label="Back"
                labelColor={colors.dark3}
                onClick={() => {
                  navigate('/reports');
                }}
                size="small"
                variant="contained"
                fontFamily="Montserrat"
              />
            </BackButton>
          )}
        </div>

        <div>
          {isMobile ? (
            <Dialog open={anchorEl}>
              <InfoBoxStyle responseValue={isMobile}>
                <InfoPopper onClickCloseHandler={() => setAnchorEl(null)} />
              </InfoBoxStyle>
            </Dialog>
          ) : (
            <Popper
              open={anchorEl}
              style={{
                position: 'absolute',
                top: '110px',
                right: '75px',
                left: 'unset',
              }}>
              <InfoBoxStyle responseValue={isMobile}>
                <InfoPopper onClickCloseHandler={() => setAnchorEl(null)} />
              </InfoBoxStyle>
            </Popper>
          )}
        </div>
      </Pagelayout>

      {isMobile && (
        <BottomNavigation
          tabData={[
            {
              key: 'back',
              icon: backImage,
              action: () => navigate(-1),
            },
          ]}
        />
      )}
    </>
  );
};

export default OverallProgressPage;

const RootDiv = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const ContentTypo = styled(Typography)(({color}) => ({
  color: color,
  textTransform: 'uppercase',
}));

const BackButton = styled('div')({
  position: 'absolute',
  bottom: '40px',
  right: '70px',
  cursor: 'pointer',
});

const InfoBoxStyle = styled(Box)(({responseValue}) => ({
  padding: '16px 24px',
  width: responseValue ? 'unset' : '347px',
  background: colors.light8,
}));

const ImageDiv = styled('div')({
  backgroundColor: colors.light4,
  padding: '8px 8px 0px 8px',
  borderRadius: '5px',
});

const DetailsWrapper = styled('div')({
  position: 'relative',
});

const DropdownToggle = styled('div')(({isOpen}) => ({
  borderRadius: '5px',
  fontFamily: 'Montserrat',
  fontSize: '15px',
  fontWeight: 700,
  listStyle: 'none',
  display: 'inline-flex',
  gap: '8px',
  padding: '8px 16px',
  height: '37px',
  backgroundColor: '#E0E3EB',
  cursor: 'pointer',
  position: 'relative',
  justifyContent: 'center',
  width: '120px',
  '&::after': {
    borderStyle: 'solid',
    borderWidth: '0.15em 0.15em 0 0',
    content: '""',
    display: 'inline-block',
    height: '0.5em',
    left: 0,
    position: 'relative',
    verticalAlign: 'top',
    width: '0.5em',
    top: isOpen ? '6px' : '5px',
    transform: isOpen ? 'rotate(314deg)' : 'rotate(136deg)',
    transition: 'transform 0.3s ease',
  },
}));

const DropdownList = styled('ul')({
  position: 'absolute',
  top: '100%',
  left: 0,
  listStyle: 'none',
  margin: 0,
  padding: 0,
  backgroundColor: '#E0E3EB',
  width: '120px',
  borderRadius: '5px',
});

const DropdownItem = styled('li')({
  padding: '8px 26px',
  cursor: 'pointer',
  width: '100%',
  fontFamily: 'Montserrat',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 500,
  borderRadius: '5px',
  '&:hover': {
    background: '#ccc',
  },
});

const HeaderExport = styled('div')({
  display: 'flex',
  gap: '10px',
});
