import React from 'react';
import {Dialog, Typography} from '@mui/material';
import {NimbleButton} from 'nimble-design-system';

import {colors} from 'theme';
import {styled} from '@mui/system';

const dialogComponent = ({onClose, open, headerText, label, noButtonHanlder, yesButtonHanlder}) => {
  return (
    <div>
      <Dailogroot onClose={onClose} open={open}>
        <Typography variant="h6" style={{fontSize: '16px'}}>
          {headerText}&nbsp;
          <LabelTypography variant="h5">{label}</LabelTypography>
        </Typography>
        <ButtonDiv>
          <NimbleButton
            color={colors.primary2}
            fontWeight="700"
            label="No"
            onClick={noButtonHanlder}
            size="large"
            variant="outlined"
            fontFamily="Montserrat"
            fontSize="16px"
          />
          <NimbleButton
            color={colors.dark4}
            fontWeight="700"
            label="Yes"
            onClick={yesButtonHanlder}
            size="large"
            variant="contained"
            fontFamily="Montserrat"
            fontSize="16px"
          />
        </ButtonDiv>
      </Dailogroot>
    </div>
  );
};
export default dialogComponent;

const ButtonDiv = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  padding: '16px 0px 0px 0px',
});

const Dailogroot = styled(Dialog)({
  '& .MuiPaper-root': {
    padding: '16px',
  },
});

const LabelTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: colors.primary2,
});
