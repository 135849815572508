import React, {useState, useMemo, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {lighten} from 'polished';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';

import {removeItem} from 'app/utils/LocalStorage';
import {actions as GeneralActions} from 'app/store/slices/general.slice';
import TypeSelectField from 'app/shared/components/selectField/TypeSelectField.component';
import {getMenuListAuthorizedForUserRoles, getMainMenuLabelByRoute} from 'app/shared/utils';

import homeIcon from 'assets/images/home-icon.svg';
import logoutIcon from 'assets/images/logout-icon.svg';
import settings from 'assets/images/settings.svg';

const mobileMenus = [
  {
    icon: homeIcon,
    selectedIcon: homeIcon,
    label: 'Home',
    route: '/user-home',
  },
  {
    icon: settings,
    selectedIcon: settings,
    label: 'Settings',
    route: '/account-settings',
  },
  {
    icon: logoutIcon,
    selectedIcon: logoutIcon,
    label: 'Log out',
    route: '/open-stix',
  },
];

const MainMenu = ({open, mobileMenuClick, isMobile, listOfWorkspace, workspaceHandler, selectedWorkspace}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = useState('Open Stix');

  const userInfo = useSelector(state => state.authentication.userInfo);

  useEffect(() => {
    if (!isMobile) {
      const mainMenuLabel = getMainMenuLabelByRoute(isMobile, location.pathname);
      if (mainMenuLabel) {
        setSelectedMenu(mainMenuLabel);
      }
    }
  }, [isMobile, location.pathname]);

  const handleClickMenuItem = ({label, route}) => {
    if (label === 'Manage') {
      selectedMenu !== 'Manage' && dispatch(GeneralActions.setManageYardstixActiveTab(1));
    }
    setSelectedMenu(label);
    mobileMenuClick?.();
    if (route) {
      navigate(route, {replace: true});
    }
    if (label === 'Log out') {
      navigate('/login', {replace: true});
      removeItem('token');
    }
  };

  const getMenuListOnBasisOfUserRole = useMemo(() => {
    const idRole = userInfo?.idRole;
    const autherizedMenus = getMenuListAuthorizedForUserRoles(idRole);
    return autherizedMenus;
  }, [userInfo]);

  const menus = useMemo(() => {
    return isMobile ? mobileMenus : getMenuListOnBasisOfUserRole;
  }, [getMenuListOnBasisOfUserRole, isMobile]);

  return (
    <div
      style={
        isMobile
          ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              margin: '0 auto',
            }
          : {
              height: '100%',
              marginTop: '30px',
              display: 'flex',
              justifyContent: 'center',
            }
      }>
      <List style={{width: '100%', padding: 8}}>
        {isMobile && (
          <TypeSelectField
            height="37px"
            surveyTypes={listOfWorkspace}
            color="var(--dark-500, #1A0909)"
            clearButton={true}
            withBorder={true}
            selectedValue={selectedWorkspace}
            onChange={workspaceHandler}
            fontWeight="600"
            fontSize="14px"
          />
        )}
        {menus.map(item => (
          <ListItem key={item.label} disablePadding sx={{display: 'block'}}>
            <ListItemButton
              style={
                isMobile
                  ? {
                      '&:hover': {
                        backgroundColor: lighten(0.1, '#450303'),
                      },
                    }
                  : {
                      height: '48px',
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      borderRadius: '5px',
                      padding: 0,
                      backgroundColor: selectedMenu === item.label ? lighten(0.1, '#450303') : '#1A0909',
                      '&:hover': {
                        backgroundColor: lighten(0.1, '#450303'),
                      },
                      width: '100%',
                    }
              }
              onClick={() => {
                handleClickMenuItem(item);
              }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                  color: '#fff',
                  display: 'flex',
                }}>
                <img src={selectedMenu === item?.label ? item.selectedIcon : item.icon} alt={item.label} />
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={{
                  opacity: open ? 1 : 0,
                  '& .MuiTypography-body1': {
                    fontFamily: 'Montserrat !important',
                    color: selectedMenu === item.label ? (isMobile ? '#FFF' : '#F85353') : '#FFF',
                    fontSize: isMobile ? '40px' : '16px',
                    fontWeight: 400,
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MainMenu;
