import {GET, POST} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  createWorkSpace: () => `${BASE_URL}/api/v1/Workspace`,
  getWorkspace: (IdUser, searchKey, pagenumber, pagesize, isAscending, ColumnName) =>
    `${BASE_URL}/api/v1/Workspace?IdUser=${IdUser}&searchKey=${searchKey}&pagenumber=${pagenumber}&pagesize=${pagesize}&isAscending=${isAscending}&ColumnName=${ColumnName}`,
  swapWorkspace: (IdWorkspaceUser, IdUser) =>
    `${BASE_URL}/api/v1/Workspace/swapWorkspace?IdWorkspaceUser=${IdWorkspaceUser}&IdUser=${IdUser}`,
  workspaceNameValidate: workSpaceName =>
    `${BASE_URL}/api/v1/Workspace/WorkSpaceValidation?WorkSpaceName=${workSpaceName}`,
};

const WorkspaceService = {
  createWorkSpace: async data => {
    return POST(urlFactory.createWorkSpace(), data);
  },

  getWorkspace: async (IdUser, searchKey, pagenumber, pagesize, isAscending, ColumnName) => {
    return GET(urlFactory.getWorkspace(IdUser, searchKey, pagenumber, pagesize, isAscending, ColumnName), null);
  },

  swapWorkspace: async (IdWorkspaceUser, IdUser) => {
    return POST(urlFactory.swapWorkspace(IdWorkspaceUser, IdUser), null);
  },

  workspaceNameValidate: async workSpaceName => {
    return GET(urlFactory.workspaceNameValidate(workSpaceName), null);
  },
};

export default WorkspaceService;
