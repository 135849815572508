import React from 'react';
import {createRoot} from 'react-dom/client';
import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';

import App from './app/App';
import {store, persistor} from 'app/store';

import reportWebVitals from './reportWebVitals';

import theme from 'theme';
import './index.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>,
);

reportWebVitals();
