import React from 'react';
import {useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import {styled} from '@mui/system';
import {colors} from 'theme';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';

const DetailReportContent = () => {
  const isMobile = useCheckIsMobile();

  const detailedReport = useSelector(state => state.report.detailedReport);

  return (
    <MainDiv isMobileView={isMobile}>
      {detailedReport.length === 0 ? (
        <Typography variant="h6">No reports available</Typography>
      ) : (
        detailedReport.map((item, index) => (
          <Subdiv key={index}>
            <Typography variant="h6">{item.name}</Typography>
            <ScaleDetailStyle>
              {[...Array(21)].map((x, i) => (
                <React.Fragment key={i}>
                  {i % 5 === 0 ? <TypoStyle1 value={i} /> : <TypoStyle2 value={i} />}
                </React.Fragment>
              ))}
              <BallColor
                backgroundValue="linear-gradient(48.58deg, #8B8B8B 16.67%, #D2D2D2 83.59%)"
                left={item.start}
              />
              <BallColor
                backgroundValue="linear-gradient(56.31deg, #CEBB12 12.67%, #FFE600 90.67%)"
                left={item.current}
              />
              {/* <BallColor
                backgroundValue="linear-gradient(262.14deg, #D22222 -4.5%, #820505 91.48%)"
                left={item.target}
              /> */}
            </ScaleDetailStyle>
          </Subdiv>
        ))
      )}
    </MainDiv>
  );
};

export default DetailReportContent;

const MainDiv = styled('div')(({isMobileView}) => ({
  width: !isMobileView && '350px',
  position: 'relative',
  // overflowX: 'hidden',
  background: colors.light8,
  borderRadius: '10px',
  padding: '24px 26px',
  // height: '90vh',
  minHeight: 'calc(70vh - 100px)',
  display: 'flex',
  flexFlow: 'column',
  gap: '24px',
  '::-webkit-scrollbar': {
    width: '0.15em',
  },
  '::webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.00)',
  },
  '::-webkit-scrollbar-thumb': {
    outline: '1px solid #ababab',
    backgroundColor: '#820505',
    borderRadius: '4px',
  },
}));

const Subdiv = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  gap: '9px',
});

const ScaleDetailStyle = styled(Typography)({
  padding: '10px',
  display: 'flex',
  position: 'relative',
  color: colors.light7,
});

const TypoStyle1 = styled(Typography)(({value}) => ({
  color: colors.light7,
  borderRight: '1px solid',
  position: 'absolute',
  left: `${value * 5}%`,
  padding: '10px 0px',
  top: '0%',
}));

const TypoStyle2 = styled(Typography)(({value}) => ({
  borderRight: '1px solid  #E2E2E2',
  position: 'absolute',
  left: `${value * 5}%`,
  padding: '8px 0px',
  top: '10%',
}));

const BallColor = styled('div')(({backgroundValue, left}) => ({
  background: backgroundValue,
  padding: '8px',
  borderRadius: '20px',
  position: 'absolute',
  top: '10%',
  left: `calc(${left} - 8px)`,
}));
