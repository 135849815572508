import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {NimbleButton, NimbleDialog} from 'nimble-design-system';
import {useSelector, useDispatch} from 'react-redux';

import {actions as ReportActions} from 'app/store/slices/report.slice';
import {colors} from 'theme';
import {commonStyleProps} from 'app/shared/constant';
import {useEffect} from 'react';

const CommentMojo = idMojo => {
  const dispatch = useDispatch();

  const mojoComments = useSelector(state => state.report.mojoCommentsData);
  const count = useSelector(state => state.report.mojoCommentCount);

  const [commentsOpen, setCommentsOpen] = useState(false);
  const [commentSize, setCommentSize] = useState(5);

  useEffect(() => {
    const mojoId = idMojo?.idMojo;
    dispatch(ReportActions.getMojoComments({mojoId, commentSize}));
  }, [commentSize, dispatch, idMojo]);

  const CommentStructureForPopup = () => {
    return (
      <div>
        {mojoComments.map((item, key) => (
          <div style={{display: 'flex', flexFlow: 'column', gap: '10px', overflow: 'auto'}} key={key}>
            <Typography variant="h6" style={{display: 'list-item', marginLeft: '16px'}}>
              {item.comment}
            </Typography>
          </div>
        ))}
      </div>
    );
  };

  const CommentStructure = () => {
    const slicedComments = mojoComments.slice(0, 5);

    return (
      <div>
        {slicedComments.map((item, key) => (
          <div style={{display: 'flex', flexFlow: 'column', gap: '10px', overflow: 'auto'}} key={key}>
            <Typography variant="h6" style={{display: 'list-item', marginLeft: '16px'}}>
              {item.comment}
            </Typography>
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      {mojoComments.length > 0 && (
        <div style={{background: colors.light9, padding: '16px', width: '100%', borderRadius: '15px'}}>
          <Typography variant="h2" color={colors.dark}>
            Comments
          </Typography>
          <CommentStructure />
          {count > 5 && (
            <div style={{width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start'}}>
              <NimbleButton
                color={colors.light11}
                labelColor="#fff"
                fontWeight="500"
                label="Show more"
                onClick={() => {
                  setCommentSize(0);
                  setCommentsOpen(true);
                }}
                size="medium"
                variant="text"
                fontFamily="Montserrat"
              />
            </div>
          )}
          <NimbleDialog
            {...commonStyleProps}
            open={commentsOpen}
            maxWidth="sm"
            onClickClose={() => {
              setCommentsOpen(false);
            }}
            onClickSecondaryAction={() => {
              setCommentsOpen(false);
            }}
            isPrimaryActionAvailable={false}
            primaryColor={colors.primary2}
            secondaryActionlabel="Back"
            title="Comments"
            cancelButtonColor={colors.dark3}>
            <CommentStructureForPopup />
          </NimbleDialog>
        </div>
      )}
    </>
  );
};

export default CommentMojo;
