import {all} from '@redux-saga/core/effects';

import authenticationSaga from './authentication.saga';
import generalSaga from './general.saga';
import userSaga from './user.saga';
import surveySaga from './survey.saga';
import reportSaga from './report.saga';
import contactAndFeedbackSaga from './contactAndFeedback.saga';
import workspaceSaga from './workspace.saga';
import groupSaga from './group.saga';
import forgotPasswordSaga from './forgotPassword.saga';
import resetPasswordSaga from './resetPassword.saga';

export default function* rootSaga() {
  yield all([
    ...authenticationSaga,
    ...generalSaga,
    ...userSaga,
    ...surveySaga,
    ...reportSaga,
    ...contactAndFeedbackSaga,
    ...workspaceSaga,
    ...groupSaga,
    ...forgotPasswordSaga,
    ...resetPasswordSaga,
  ]);
}
