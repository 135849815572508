import React from 'react';
import {Typography} from '@mui/material';
import {styled} from '@mui/system';

import {colors} from 'theme';

import closeIcon from 'assets/images/close-icon-without-border.svg';

const infoJson = [
  {
    id: 1,
    description:
      'In the report screen you are able to see the results of the input your employees have given on the corresponding stix.',
    label: '',
  },
  {
    id: 2,
    description:
      'The grey “Start” indicator shows the starting point of your employees, which is based on the first stix they filled in.',
    background: 'linear-gradient(48.58deg, #8B8B8B 16.67%, #D2D2D2 83.59%)',
    label: 'Start',
  },
  {
    id: 3,
    description:
      'The yellow “Current” indicator shows the current state your employees are in, based on the most recently filled in stix.',
    background: 'linear-gradient(56.31deg, #CEBB12 12.67%, #FFE600 90.67%)',
    label: 'Current',
  },
];

const infoPopper = ({onClickCloseHandler}) => {
  return (
    <>
      <MainDiv>
        <ContentColor variant="h2" color={colors.primary2}>
          INFO
        </ContentColor>
        <img style={{cursor: 'pointer'}} src={closeIcon} alt="close" onClick={onClickCloseHandler} />
      </MainDiv>
      {infoJson.map((item, key) => {
        return (
          <div key={key}>
            <ContentDiv label={item.label}>
              <BackgroundTypo backgroundvalue={item.background} />
              <ContentColor variant="h6" color={colors.dark}>
                {item.label && item.label}
              </ContentColor>
            </ContentDiv>
            <ContentColor variant="h6" color={colors.dark}>
              {item.description}
            </ContentColor>
          </div>
        );
      })}
    </>
  );
};

export default infoPopper;

const MainDiv = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const ContentColor = styled(Typography)(color => ({
  color: color,
}));

const ContentDiv = styled('div')(({label}) =>
  label
    ? {display: 'flex', gap: '5px', alignItems: 'center', padding: '8px 0px'}
    : {display: 'flex', gap: '5px', alignItems: 'center'},
);

const BackgroundTypo = styled(Typography)(({backgroundvalue}) => ({
  background: backgroundvalue,
  padding: '8px',
  borderRadius: '20px',
  width: 15,
  height: 15,
}));
