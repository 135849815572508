import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import MobileDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from 'react-router-dom';
import {ListItemButton, ListItemIcon, ListItemText, IconButton, Button, AppBar, Toolbar} from '@mui/material';
import {UXFeedbackFloater} from 'ux-feedback-floating-button';
import {useDispatch, useSelector} from 'react-redux';
import {NimbleButton} from 'nimble-design-system';
import {capitalize} from 'lodash';

import {useApiResponseHandler} from 'app/utils/CustomHooks';
import MainMenu from './MainMenu';
import {colors} from 'theme';
import {removeItem} from 'app/utils/LocalStorage';
import {getWithExpiry} from 'app/utils/LocalStorage';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';
import TypeSelectField from 'app/shared/components/selectField/TypeSelectField.component';
import {actions as workspaceAction} from 'app/store/slices/workspace.slice';
import {actions as GeneralActions} from 'app/store/slices/general.slice';

import CloseIcon from 'assets/images/close-icon.svg';
import LogoutIcon from 'assets/images/logout-icon.svg';
import desktopLogo from 'assets/images/desktop_logo.svg';
import desktopLeftArrow from 'assets/images/desktop_leftArrow.svg';
import newLogo from 'assets/images/hideMenuLogo.svg';
import yardstixIcon from 'assets/images/yardstix-icon.svg';
const drawerWidth = 176;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerFooter = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  // padding: theme.spacing(0, 1),
  marginBottom: '16px',
}));

const DesktopDrawer = styled(MobileDrawer, {shouldForwardProp: prop => prop !== 'open'})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Layout = props => {
  const {window} = props;
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [listOfWorkspace, setListOfWorkspace] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const navigate = useNavigate();
  const token = getWithExpiry('token');
  const isMobile = useCheckIsMobile();
  const dispatch = useDispatch();

  const workspaceData = useSelector(state => state.workspace.workspaceAllData?.userWorkSpaces);
  const userInfo = useSelector(state => state.authentication.userInfo);
  const swapWorkspaceLoading = useSelector(state => state.workspace.swapWorkspaceLoading);
  const swapWorkspaceError = useSelector(state => state.workspace.swapWorkspaceError);

  useEffect(() => {
    if (userInfo) {
      dispatch(
        workspaceAction.getWorkspace({
          IdUser: userInfo?.idUser,
          searchKey: null,
          pagenumber: 1,
          pagesize: 100,
          isAscending: true,
          ColumnName: 'Name',
        }),
      );
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (workspaceData) {
      const temp = workspaceData?.map(item => ({
        label: item.workspaceName,
        value: item.idWorkspaceUser,
        isActive: item.active,
        idUser: item.idUser,
      }));
      const findWorkspace = temp.filter(item => item.isActive === true);
      setListOfWorkspace(temp);
      setSelectedWorkspace(findWorkspace[0]);
    }
  }, [workspaceData, userInfo]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const workspaceHandler = (e, newValue) => {
    setSelectedWorkspace(newValue);
    const params = {
      IdWorkspaceUser: newValue?.value,
      IdUser: newValue?.idUser,
    };
    dispatch(workspaceAction.swapWorkspace(params));
  };

  const successHandler = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
    dispatch(
      GeneralActions.setAlertData({
        variant: 'success',
        message: 'Swap workspace successfully!',
      }),
    );
    navigate('/user-home');
  };

  const errorHandler = () => {
    dispatch(
      GeneralActions.setAlertData({
        variant: 'error',
        message: 'Swap workspace error!',
      }),
    );
  };

  useApiResponseHandler(swapWorkspaceLoading, swapWorkspaceError, successHandler, errorHandler);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100%)`},
          ml: {sm: `${drawerWidth}px`},
          display: {xs: 'block', sm: 'none'},
          height: '80px',
          background: '#1A0909',
        }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0,
            height: '100%',
            marginLeft: '20px',
            marginRight: '20px',
          }}>
          <img src={yardstixIcon} alt="logo" style={{marginLeft: '24px'}} />
          <Button
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{display: {sm: 'none'}}}>
            <MenuIcon style={{color: '#fff', width: '30px', height: '30px'}} />
          </Button>
        </Toolbar>
      </AppBar>
      <DesktopDrawer
        variant="permanent"
        open={open}
        sx={{
          display: {xs: 'none', sm: 'block'},
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#1A0909',
            color: '#fff',
          },
        }}>
        <DrawerHeader style={{justifyContent: 'center', flexFlow: 'column'}}>
          <IconButton style={{marginTop: '40px', flexFlow: 'column', gap: '15px', height: '100px'}}>
            {!open ? (
              <>
                <img src={newLogo} alt="close" />
                <MenuIcon style={{color: '#fff', marginRight: 3}} onClick={handleDrawerOpenClose}></MenuIcon>
              </>
            ) : (
              <div
                style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                <img src={desktopLogo} alt="close" />
                <img
                  src={desktopLeftArrow}
                  alt="close"
                  width="30"
                  height="30"
                  style={{marginTop: '15px'}}
                  onClick={handleDrawerOpenClose}
                />
              </div>
            )}
          </IconButton>
          <div style={{display: 'contents'}}>
            {open ? (
              <TypeSelectField
                height="37px"
                surveyTypes={listOfWorkspace}
                color="var(--dark-500, #1A0909)"
                clearButton={true}
                withBorder={true}
                selectedValue={selectedWorkspace}
                onChange={(e, newValue) => workspaceHandler(e, newValue)}
                fontWeight="600"
                fontSize="14px"
              />
            ) : (
              <NimbleButton
                color={colors.dark3}
                labelColor="#fff"
                fontWeight="700"
                label={capitalize(selectedWorkspace?.label.charAt(0))}
                onClick={() => {
                  setOpen(true);
                }}
                size="medium"
                variant="contained"
                fontFamily="Montserrat"
              />
            )}
          </div>
        </DrawerHeader>
        <MainMenu open={open} />
        <DrawerFooter>
          <div style={{display: 'flex', width: '100%'}}>
            <ListItemButton
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
              onClick={() => {
                navigate('/login', {replace: true});
                removeItem('token');
              }}>
              {!open ? (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: '#fff',
                  }}>
                  <img src={LogoutIcon} alt="logout" />
                </ListItemIcon>
              ) : (
                <ListItemText
                  primary={'Log out'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-body1': {
                      fontFamily: 'Montserrat !important',
                      color: '#FFFFFE',
                      fontSize: '16px',
                      fontWeight: 400,
                    },
                  }}
                />
              )}
            </ListItemButton>
          </div>
        </DrawerFooter>
      </DesktopDrawer>
      <MobileDrawer
        anchor={'top'}
        container={container}
        variant="temporary"
        open={mobileOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          // onBackdropClick: handleDrawerToggle,
          onClose: handleDrawerToggle,
        }}
        sx={{
          display: {xs: 'block', sm: 'none'},
          '& .MuiDrawer-paper': {boxSizing: 'border-box', width: '100%'},
        }}
        PaperProps={{
          sx: {
            background: '#450303',
            color: '#fff',
            height: '100vh',
          },
        }}>
        <DrawerHeader
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <IconButton onClick={handleDrawerToggle}>{mobileOpen && <img src={CloseIcon} alt="close" />}</IconButton>
        </DrawerHeader>

        <MainMenu
          open={mobileOpen}
          mobileMenuClick={() => {
            setMobileOpen(false);
          }}
          isMobile
          listOfWorkspace={listOfWorkspace}
          selectedWorkspace={selectedWorkspace}
          workspaceHandler={(e, newValue) => workspaceHandler(e, newValue)}
        />
      </MobileDrawer>
      {!isMobile && (
        <UXFeedbackFloater
          token={token}
          appName="Yardstix"
          workspaceCode="Nimble Institute - yardstix_746BDBD1-1019-40DF-9992-8353DE473B3B"
        />
      )}
    </>
  );
};

export default Layout;
