import {GET, PUT, POST} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  getToken: () => `${BASE_URL}/Token`,
  getUserInfo: () => `${BASE_URL}/me`,
  updateTnC: () => `${BASE_URL}/api/v1/Users/UpdateTnC`,
  postUserLogin: () => `${BASE_URL}/api/Accounts/token`,
};

const AuthenticationService = {
  getToken: async (redirectUrl, code) => {
    const params = {
      redirect_uri: redirectUrl,
      code,
    };
    const authRequired = false;
    return GET(urlFactory.getToken(), params, authRequired);
  },
  getUserInfo: async => {
    return GET(urlFactory.getUserInfo());
  },
  updateTnC: async idUser => {
    return PUT(urlFactory.updateTnC(idUser), null, {idUser});
  },
  postUserLogin: async payload => {
    return POST(urlFactory.postUserLogin(), payload);
  },
};

export default AuthenticationService;
