import {call, put, takeLatest} from 'redux-saga/effects';

import AuthenticationService from 'app/api/authentication.service';
import {actions as authenticationAction} from 'app/store/slices/authentication.slice';

function* watchHandleGetToken(action) {
  const {redirectUrl, code} = action.payload;
  try {
    const response = yield call(AuthenticationService.getToken, redirectUrl, code);
    yield put(authenticationAction.getTokenSuccess(response));
    yield put(authenticationAction.getUserInfo({selectedWorkspace: 0}));
  } catch (error) {
    yield put(authenticationAction.getTokenFailure(error));
  }
}

function* watchGetUserInfo(action) {
  try {
    const response = yield call(AuthenticationService.getUserInfo);
    const data = {response};
    yield put(authenticationAction.getUserInfoSuccess(data));
  } catch (error) {
    yield put(authenticationAction.getUserInfoFailure(error));
  }
}

function* watchUpdateTnC(action) {
  const {idUser} = action.payload;
  try {
    const response = yield call(AuthenticationService.updateTnC, idUser);
    yield put(authenticationAction.updateTnCSuccess(response));
  } catch (error) {
    yield put(authenticationAction.updateTnCFailure(error));
  }
}

function* watchHandleUserLogin(action) {
  try {
    const response = yield call(AuthenticationService.postUserLogin, action.payload);
    yield put(authenticationAction.handleUserLoginSuccess(response));
    yield put(authenticationAction.getUserInfo({selectedWorkspace: 0}));
  } catch (error) {
    yield put(authenticationAction.handleUserLoginFailure(error));
  }
}
const authenticationSaga = [
  takeLatest(authenticationAction.getToken, watchHandleGetToken),
  takeLatest(authenticationAction.getUserInfo, watchGetUserInfo),
  takeLatest(authenticationAction.updateTnC, watchUpdateTnC),
  takeLatest(authenticationAction.handleUserLogin, watchHandleUserLogin),
];

export default authenticationSaga;
