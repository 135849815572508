import {GET} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  getOverallReport: (Idsurvey, IdUserGrpSurvey) =>
    `${BASE_URL}/SummaryReport?Idsurvey=${Idsurvey}&IdUserGrpSurvey=${IdUserGrpSurvey}`,
  getDetailedReport: () => `${BASE_URL}/TopicReport`,
  getSurveyReport: (searchData, startDate, endDate, workspaceId, sortNewToOld) =>
    `${BASE_URL}/SurveyReports?searchKey=${searchData}&StartDate=${startDate}&EndDate=${endDate}&workspaceId=${workspaceId}&SortNewToOld=${sortNewToOld}`,
  getMojoReport: () => `${BASE_URL}/MojoReport`,
  getMojoComments: () => `${BASE_URL}/MojoComments`,
  getSurveyExcelFile: IdSurvey => `${BASE_URL}/StixExcel?IdSurvey=${IdSurvey}`,
  getMojoExcelFile: IdSurvey => `${BASE_URL}/MojoExcel?IdSurvey=${IdSurvey}`,
};

const ReportService = {
  getOverallReport: async (Idsurvey, IdUserGrpSurvey) => {
    return GET(urlFactory.getOverallReport(Idsurvey, IdUserGrpSurvey), {});
  },
  getDetailedReport: async Idsurvey => {
    return GET(urlFactory.getDetailedReport(), {Idsurvey});
  },
  getSurveyReport: async (searchData, startDate, endDate, workspaceId, sortNewToOld) => {
    return GET(urlFactory.getSurveyReport(searchData, startDate, endDate, workspaceId, sortNewToOld), null);
  },
  getMojoReport: async IdSurveyInstance => {
    return GET(urlFactory.getMojoReport(), {IdSurveyInstance});
  },
  getMojoComments: async (mojoId, commentSize) => {
    const params = {
      IdSurvayInstance: mojoId,
      PageSize: commentSize,
    };
    return GET(urlFactory.getMojoComments(), params);
  },

  getExcelFile: async requestParam => {
    const {Type, id} = requestParam;

    if (Type === 'Mojo') {
      return await GET(urlFactory.getMojoExcelFile(id));
    } else {
      return await GET(urlFactory.getSurveyExcelFile(id));
    }
  },
};

export default ReportService;
