import React, {useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {actions as authenticationActions} from 'app/store/slices/authentication.slice';
import {actions as GeneralActions} from 'app/store/slices/general.slice';
import {useApiResponseHandler} from 'app/utils/CustomHooks';

const SSOValidator = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector(state => state.authentication.userInfo);
  const userInfoLoading = useSelector(state => state.authentication.userInfoLoading);
  const userInfoError = useSelector(state => state.authentication.userInfoError);

  const handleError = () => {
    navigate('/login', {replace: true});
    dispatch(
      GeneralActions.setAlertData({
        variant: 'error',
        message: 'Login failed',
        info: 'Server error',
      }),
    );
  };

  const handleSuccessUserInfo = () => {
    const {acceptTnC, idWorkspace, isNotRegistered, active, idStatus} = userInfo;
    if (isNotRegistered || !active) {
      dispatch(
        GeneralActions.setAlertData({
          variant: 'error',
          message: 'Login Failed',
          info: 'Please check your access',
        }),
      );
      navigate('/login', {replace: true});
    } else {
      if (idStatus === 3) {
        navigate('/set-name');
      } else if (!acceptTnC) {
        navigate('/terms');
      } else if (idWorkspace === null) {
        navigate('/create-first-workspace');
      } else {
        navigate('/user-home');
      }
    }
  };

  useApiResponseHandler(userInfoLoading, userInfoError, handleSuccessUserInfo, handleError);

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      const redirectUrl = process.env.REACT_APP_REDIRECT_URL ?? 'http://localhost:3000';
      dispatch(authenticationActions.getToken({redirectUrl, code}));
    } else {
      navigate('/login', {replace: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Box sx={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <CircularProgress size={50} />
    </Box>
  );
};

export default SSOValidator;
